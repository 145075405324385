@import '~@density/dust/dist/tokens/dust.tokens';

.loading {
  width: 100%;
  margin-top: 64px;
  text-align: center;
  font-size: 18px;
  color: $gray-200;
}

.container {
  margin: 4em auto;
  width: 600px;
}

.button {
  margin: 2em 0;
  text-align: right;
}

.gridButton {
  text-align: right;
}

input[type=number] {
  font-size: 1.5em;
}

.dialogOptionTitle {
  margin: 0.5em 0;
  font-size: 1.1em;
}

.summaryAddress {
  margin-left: 16px;
}

.summaryAddress p {
  margin-top: 8px;
  margin-bottom: 8px;
}

.loadingLogoWrapper {
  margin-top: 64px;
  display: flex;
  justify-content: center;
}

.densityLogo {
  width: 60px;
  margin: 3em;
  animation: loader-image-spin infinite 2s linear;
}

@keyframes loader-image-spin {
  from {
      transform: rotate(0deg);
  }
  to {
      transform: rotate(360deg);
  }
}

.staticDensityLogo {
  width: 50px;
}

.graphicsContainer {
  position: relative;
  width: 60px;
  height: 60px;
  padding-top: 100px;
}
.graphic {
  width: 60px;
  height: 60px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: 5px;
}

.graphic1 {
  width: 50px;
  height: 50px;
  animation: spinFadeOut 1.5s ease-in forwards;
}

.graphic2 {
  opacity: 0;
  animation: spinFadeIn 1.0s linear 0.5s forwards;
}

@keyframes spinFadeOut {
  0% {
    transform: translate(-50%, -50%) rotate(0);
    opacity: 1;
  }
  100% {
    transform: translate(-50%, -50%) rotate(1080deg);
    opacity: 0;
  }
}

@keyframes spinFadeIn {
  0% {
    transform: translate(-50%, -50%) rotate(0);
    opacity: 0;
  }
  100% {
    transform: translate(-50%, -50%) rotate(1080deg);
    opacity: 1;
  }
}
